/* styles.css */
.rotate {
  animation: spin 1s infinite linear;
}

.notary-rotate {
  animation: notary-spin 4s infinite linear;
}

.react-calendar {
  font-size: 16px; /* Increase font size to make the calendar larger */
}

.MuiFormControlLabel-label.Mui-required::after {
  content: none;
}

/* @media (min-width: 1200px) {
  .MuiInputLabel-root {
    width: 85%;
  }
} */

.bottomCard {
  padding-bottom: 0 !important;
}

.bottomCard-6 {
  padding-bottom: 6px !important;
}

/* Adjust according to your needs */
@media (min-width: 768px) {
  .react-calendar {
    font-size: 20px; /* Larger on wider screens */
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes notary-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.cursor-normal {
  cursor: auto !important;
}

.error-timepicker .MuiInputBase-input {
  color: red; /* Adjust as needed */
}

.custom-tooltip {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  min-width: 7rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip-text {
  font-size: 18px;
  font-weight: bold;
}

.tooltip-header {
  font-size: 20px;
  font-weight: bold;
  color: #26648a;
}

.custom-tooltip > * {
  margin-bottom: 6px;
}

.iconButton:hover {
  border-radius: 15px !important;
}

/* File: src/styles.css */
.custom-checkbox {
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.custom-checkbox.checked {
  background-color: #3f51b5;
  border-color: #3f51b5;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 7L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.59L21 7Z'/%3E%3C/svg%3E");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes grow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.fadeInBtn {
  animation: fadeIn 1s forwards;
  animation: shrink 1s forwards;
}

.fadeIn {
  animation: fadeIn 2s forwards;
}

.moveUp {
  animation: moveUp 1s forwards;
}

.growBtn {
  animation: grow 1s forwards;
}

.fadeOut {
  animation: fadeOut 1s forwards;
}

.fadeInPlaceHolder1 {
  animation: fadeIn 1s forwards;
}
.fadeInPlaceHolder2 {
  animation: fadeIn 2s forwards;
}
.fadeInPlaceHolder3 {
  animation: fadeIn 3s forwards;
}
.fadeInPlaceHolder4 {
  animation: fadeIn 4s forwards;
}
.fadeInPlaceHolder5 {
  animation: fadeIn 5s forwards;
}
.fadeInPlaceHolder6 {
  animation: fadeIn 6s forwards;
}
.fadeInPlaceHolder7 {
  animation: fadeIn 7s forwards;
}
.fadeInPlaceHolder8 {
  animation: fadeIn 8s forwards;
}

/* transitions.css */
.fade-enter {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit-active {
  opacity: 0;
}

/* CREATE NOC FIELDS ANIMATION */

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.field {
  opacity: 0;
  transform: translateY(-20px);
}

/* Opening Animations */
.open .field-1 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 0.1s; /* Delay for first field */
}

.open .field-2 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 0.3s; /* Delay for second field */
}

.open .field-3 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 0.5s; /* Delay for second field */
}

.open .field-4 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 0.7s; /* Delay for second field */
}

.open .field-5 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 0.9s; /* Delay for second field */
}

.open .field-6 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 1.1s; /* Delay for second field */
}

.open .field-7 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 1.3s; /* Delay for second field */
}

.open .field-8 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 1.5s; /* Delay for second field */
}

.open .field-9 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 1.7s; /* Delay for second field */
}

.open .field-10 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 1.9s; /* Delay for second field */
}

.open .field-11 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.1s; /* Delay for second field */
}

.open .field-12 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.3s; /* Delay for second field */
}

.open .field-13 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.5s; /* Delay for second field */
}

.open .field-14 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}

.open .field-15 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-16 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-17 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-18 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-19 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-20 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-21 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-22 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-23 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-24 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-25 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-26 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-27 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-28 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-29 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-30 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}
.open .field-31 {
  animation: slideIn 0.3s ease-in forwards;
  animation-delay: 2.7s; /* Delay for second field */
}

/* Closing Animations (Only the first 5 fields animate out) */
.closing .field-1,
.closing .field-2,
.closing .field-3,
.closing .field-4,
.closing .field-5,
.closing .field-6,
.closing .field-7,
.closing .field-8,
.closing .field-9,
.closing .field-10,
.closing .field-11,
.closing .field-12,
.closing .field-13,
.closing .field-14,
.closing .field-15,
.closing .field-16,
.closing .field-17,
.closing .field-18,
.closing .field-19,
.closing .field-20,
.closing .field-21,
.closing .field-22,
.closing .field-23,
.closing .field-24,
.closing .field-25,
.closing .field-26,
.closing .field-27,
.closing .field-28,
.closing .field-29,
.closing .field-30,
.closing .field-31 {
  animation: slideOut 0.3s ease-out forwards;
  animation-delay: 0s;
}

/* Hide fields completely when not visible */
.field {
  display: none; /* Hide by default */
}

.open .field {
  display: block; /* Display fields when opening */
}

.closing .field-1,
.closing .field-2,
.closing .field-3,
.closing .field-4,
.closing .field-5 {
  display: block; /* Keep fields visible during closing animation */
}

/* DOWNLOAD PROMO POPUP */
@keyframes popupShrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes popupGrow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

.popupShrink {
  animation: popupShrink 0.3s forwards;
}

.popupGrow {
  animation: popupGrow 0.3s forwards;
}

.light-yellow-row {
  background-color: #fff4c0 !important; /* Light yellow */
}

.selected-row-color {
  background-color: #ebf1f4 !important; /* Light grey */
}

@keyframes editContractorFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media print {
  .magnifierImg {
    display: none;
  }
}
